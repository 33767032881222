/* The main global stylesheet */

@import "variables";
@import "bootstrap-theme";
@import "~bootstrap/scss/bootstrap";
@import "~handsontable-pro/dist/handsontable.full.css";
@import "handsontable-theme";
@import "toastify.scss";
@import "~react-datepicker/dist/react-datepicker.css";
@import "_datepicker.scss";
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.spinnerDiv {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 45px;
  z-index: 100;
  background: white;
}

.no-data--simple-header-text {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #ce0e29;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

label.required::after {
  content: " (required)";
}

.navbar {
  ion-icon {
    color: $red;
    font-size: 26px;
    margin-bottom: -6px;
    margin-right: 4px;
  }
  .nav-item {
    padding: 0 7px 0 10px;
  }
  .nav-item:nth-child(n + 2) {
    border-left: solid 1px $gunmetal;
  }
  .dropdown-toggle::after {
    border-width: 0.5em;
    margin-left: 0.5em;
    vertical-align: 0;
    color: white;
  }
  .dropdown-bar {
    height: 30px;
  }
}

.icon-button {
  display: inline-flex;
  border: none;
  background: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

.rotating {
  -webkit-animation: rotation 6s infinite linear;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

.middle-page-relative {
  height: 100px;
  width: 450px;
  position: absolute;
  left: 50%;
  margin-left: -225px;
  top: 50%;
  margin-top: -50px;
}

.client-error--simple-header-text {
  width: 470px;
  color: #ce0e29;
}

@keyframes fadein {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 0.2;
  }
}

// toast notifications
.left-justified-toast {
  text-align: left;
  padding: 10px;
}

.btn-height-62-per-cent {
  height: 62%;
}

.btn-small {
  background-color: #6c757d;
  border-radius: 7px;
  margin-right: 5px;
  margin-left: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: bold;
  border: 1px solid black;
  color: white;
  outline: none;
  transition: 0.5s ease-in-out;
  padding: 2px 3px;
}

.btn-small:hover {
  background-color: #828282;
  color: white;
  transition: 0.5s ease-in-out;
}

.border-callout {
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid #eee;
  border-left-width: 0.25rem;
  border-radius: 0.25rem;
}

.position-relative {
  position: relative;
}

.position-bottom {
  position: absolute;
  bottom: 0;
}

.margin-left-5px {
  margin-left: 5px;
}

.margin-left-10px {
  padding-left: 10px !important;
}

.margin-left-20px {
  padding-left: 20px !important;
}

.position-center-absolute {
  position: absolute;
  top: 50%;
  left: 25%;
}

.hotel-setup-property,
.hotel-setup-geocode-query-reset {
  float: right;
  height: 20px;
  margin-top: -30px;
  width: 20px;
}

.hotel-setup-geocode-query-reset {
  cursor: pointer;
}

.card-corner-relative-20x20 {
  position: relative;
  top: 20px;
  right: 20px;
}

.status-bar-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 30px;
  background-color: #333333;
  color: white;
  z-index: 9999;
}

.status-active {
  color: #f28140;
}

.status-pill {
  border-radius: 1rem;
  background-color: white;
}

.margin-left-minus4Percent {
  margin-left: -4%;
}

.margin-left-plus4Percent {
  margin-left: 4%;
}

.margin-left-minus5Percent {
  margin-left: -5%;
}

.margin-left-plus5Percent {
  margin-left: 5%;
}

.pulse {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background: #cca92c;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
  animation: pulse 2s infinite;
}

.pulse:hover {
  animation: none;
}

.fade-from-active-to-inactive {
  color: $statusInactive;
  animation: fadeToGray 15s;
}

@keyframes fadeToGray {
  0% {
    color: $statusActive;
  }
  100% {
    color: $statusInactive;
  }
}

.status-loader-pulse {
  width: 30px;
  height: 30px;
  background-color: white;
  border-radius: 100%;
  -webkit-animation: statusLoaderPulseScaleout 1s infinite ease-in-out;
  animation: statusLoaderPulseScaleout 1s infinite ease-in-out;
}

@-webkit-keyframes statusLoaderPulseScaleout {
  0% {
    -webkit-transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@keyframes statusLoaderPulseScaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

.badge-pill {
  border-radius: 50%;
}

.row-control-buttons {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.bordered-row {
  border: 1px silver solid;
  border-radius: 5px;
  padding-top: 8px;
}

.column-label {
  margin: 0.1em;
}

.fake-modal {
  display: block;
  padding-right: 15px;
  background-color: #333333 !important;
  opacity: 0.5;
}

.fake-modal-content {
  display: block;
  bottom: 50%;
  z-index: 9999;
}

.client-error--simple-header-text {
  width: 470px;
  color: #ce0e29;
}

.table-sm td {
  padding: 0.1rem;
}

@keyframes fadein {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 0.2;
  }
}

.restriction-edited {
  background: $red;
}

.restriction-removed {
  background: $red;
  -webkit-animation: fadein 4s infinite;
  animation: fadein 4s infinite;
}

.restriction-published {
  background: $black;
}

// toast notifications
.left-justified-toast {
  text-align: left;
  padding: 10px;
}

.htContextMenu table.htCore {
  border: 0px solid #ccc;
  border-bottom-width: 0px;
  border-right-width: 0px;
}

.mrl10 {
  margin-left: 10%;
}

.margin-right-5px {
  margin-right: 5px;
}

.margin-right-2px {
  margin-right: 2px;
}

.padding-right-5px {
  padding-right: 5px;
}

.padding-right-2px {
  padding-right: 2px;
}

.row-button-full-width {
  width: 100%;
  text-align: left;
}

.dropdown-toggle {
  position: relative;
}
.dropdown-toggle::after {
  position: relative;
  float: right;
  top: 10px;
}

.minlos-restriction {
  text-decoration: underline;
}

.maxlos-restriction {
  text-decoration: overline;
}

.dropdown-item {
  &:hover {
    background: rgba(#000, 0.1);
  }
}

.bigradio {
  width: 1em;
  height: 1em;
  margin-left: 5px;
}

.hotelName {
  width: 20%;
  text-align: center;
  font-weight: bold;
  letter-spacing: 1px;
  font-family: sans-serif;
  color: white;
  position: absolute;
  left: 40%;
  transform: translate(-50%);
}

.hotelIcon {
  color: #ce0e29;
  margin-right: 10px;
  cursor: pointer;
}

.hotelDropdown {
  width: min-content;
}

.revNavBar {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  position: absolute;
  right: 0;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.MuiFormGroup-root {
  display: inline !important;
}

.MuiFormControl-root {
  width: 100%;
}

.globalModal {
  background-color: black;
  opacity: 70%;
}

.btn-history {
  color: white;
  background-color: $dark;
  border: none;
}

.redReverse {
  color: white !important;
  background-color: #f14141 !important;
}

.modalClose {
  color: white !important;
  background-color: #f14141 !important;
  border-radius: 7px !important;
  border: 1px solid black !important;
  float: right !important;
  padding: 5px !important;
}

.basicInput {
  border: 1px solid darkgrey;
  border-radius: 5px;
}

.invalidInput {
  border: 1px solid red;
  border-radius: 5px;
}

.red {
  color: $red;
}

.error {
  color: $red;
  font-size: larger;
  font-weight: bold;
  float: right;
  margin-right: 50%;
}

#simple-tabpanel-1
  > div
  > div:nth-child(3)
  > div
  > div:nth-child(2)
  > div
  > div
  > table
  > tbody
  > tr
  > td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-paddingNone
  > div
  > button[title~="Delete"] {
  display: none;
}

#simple-tabpanel-1
  > div
  > div:nth-child(3)
  > div
  > div.MuiToolbar-root.MuiToolbar-regular.MTableToolbar-root-70.MuiToolbar-gutters
  > div.MTableToolbar-actions-73
  > div
  > div
  > span:nth-child(2)
  > button {
  display: none;
}

#simple-tabpanel-0
  > div
  > div:nth-child(3)
  > div
  > div:nth-child(2)
  > div
  > div
  > table
  > tbody
  > tr:not(:last-child)
  > td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-paddingNone
  > div
  > button:nth-child(2) {
  display: none;
}

#hot-display-license-info {
  display: none !important;
}

.MuiTypography-colorTextSecondary {
  color: rgba(0, 0, 0, 0.8) !important;
}

.fade-enter {
  opacity: 0;
  height: 0;
}

.fade-enter-active {
  opacity: 1;
  height: 55px;
  transition: all 300ms ease-out;
  /* Timing*/
  transition-delay: 100ms;
}

.fade-exit {
  opacity: 1;
  height: 55px;
}

.fade-exit-active {
  opacity: 0;
  height: 0px;
  transition: all 300ms ease-in-out;
}

.message_ticker {
  width: 98%;
  height: 50px;
  overflow: hidden !important;
}

.message_ticker:hover {
  cursor: pointer;
}

.message_ticker_new {
  width: 98%;
  height: 50px;
  overflow: hidden !important;
}

.message_ticker_new:hover {
  -webkit-transition: 4s ease-in;
  -moz-transition: 4s ease-in;
  -o-transition: 4s ease-in;
  transition: 4s ease-in;
  text-indent: -100%;
  cursor: pointer;
}

.eventTableCell {
  border-bottom: 1px solid darkgrey !important;
}

.none {
  background-color: white !important;
  color: black;
}

.dropdown-heading {
  height: 56px !important;
}

.dropdown-heading-value {
  line-height: 56px !important;
}

.multi-select {
  margin-left: 25px;
  min-width: 320px !important;
  max-width: 320px !important;
}

.compsetLoad {
  position: absolute;
  // top: 5%;
  margin-top: 5vh;
  right: 10px;
  height: 100%;
  background-color: white;
  z-index: 100;
  width: 100%;
}

.MuiAutocomplete-option[data-focus="true"] {
  background-color: #aaa5a5 !important;
}
.datePicketDashboard {
  border: none;
  text-align: center !important;
}

/* Dropdown Button */
.dropbtn-ota {
  color: black;
  // padding: 5px;
  font-size: 16px;
  border: 1px solid;
  width: 100px;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  text-align: left;
}

/* Dropdown button on hover & focus */

/* The container <div> - needed to position the dropdown content */
.dropdown-ota {
  position: absolute;
  display: inline-block;
  bottom: 5px;
}

.dropdown-ota :hover,
.dropdown-content-ota {
  display: inline-block;
}

.dropdown-content-ota {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  width: 90px;
  // box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  bottom: 27px;
  left: 0;
}

.otaOptions {
  color: black;
  width: inherit;
}

.otaOptions:hover {
  background-color: rgb(116, 114, 114);
}

.overrideSpan {
  position: absolute;
  display: inline-block;
  right: 118px;
  background: white;
  border: 1px solid;
  width: 100px;
  height: 25px;
  text-align: left;
  padding-left: 4px;
}

.content {
  padding-top: 50px; /* Adjust this value based on the height of your Navbar */
}
