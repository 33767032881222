.homeBody {
  /* background-color: #ce0e29; Dull red color */
}

.homePageLogo {
  width: min-content;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  margin-top: 5vh;
}

.hotelCards {
  padding: 0 5vw 0 5vw;
  display: grid;
  grid-gap: 20px;
  margin-top: 60px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  margin-bottom: 20px;
}

.cards {
  /* word-wrap:normal; */
  height: min-content;
  padding: 15px;
  border: 1px solid rgb(187, 181, 181);
  border-radius: 5px;
  background-color: white;
  cursor: pointer;
}

.apartmentIcon {
  font-size: 50px !important;
  width: min-content;
  position: relative;
  left: 50%;
  top: 50% !important;
  bottom: 50% !important;
  transform: translate(-50%);
}

.hotelFullName {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;
  position: relative;
  left: 50%;
  top: 20%;
  transform: translateX(-50%);
  color: rgb(99, 91, 91);
}

.hotelButton {
  font-weight: bold;
  position: relative;
  left: 50%;
  bottom: 2%;
  transform: translate(-50%);
  padding: 5px 20px 5px 20px;
  border-radius: 5px;
  background-color: #ce0e29;
  border-color: #ce0e29;
  color: white;
}

.hotelSearch {
  width: 40%;
  margin: auto;
  margin-top: 5vh;
}

/* media query for height */

@media (max-height: 600px) {
  .apartmentIcon {
    font-size: 40px !important;
    top: 15% !important;
  }

  .hotelFullName {
    top: 10%;
  }
}
